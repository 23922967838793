









import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CategoryDescription',
  props: {
    description: {
      type: String,
      required: true,
    },
  },
});
